<template>
  <hr class="divider-line" />

  <div class="billing-form-container">
    <h4>Billing Address</h4>

    <div class="input-field checkbox-field">
      <input
        type="checkbox"
        role="checkbox"
        id="billing-is-shipping"
        value="true"
        :true-value="true"
        :false-value="false"
        aria-labelledby="billing-is-shipping-label"
        tabindex="0"
        v-model="billingIsShipping"
        @change="toggleBillingIsShippingAddress()"
      />

      <IconValid />

      <label id="billing-is-shipping-label" for="billing-is-shipping">
        Billing address is the same as delivery
      </label>
    </div>

    <AddressForm addressType="billing" key="billing" v-show="!billingIsShipping" />
  </div>
  <hr />
</template>

<script>
// Stores
import { mapState, mapWritableState, mapActions } from 'pinia';
import { useShippingStore } from '@/stores/ShippingStore.ts';

// Components
import AddressForm from './../Form/index.vue';
import IconValid from '@/components/icons/IconValid.vue';

export default {
  name: 'BillingForm',
  components: {
    AddressForm,
    IconValid,
  },
  computed: {
    ...mapState(useShippingStore, ['address']),
    ...mapWritableState(useShippingStore, ['billingIsShipping']),
  },
  methods: {
    ...mapActions(useShippingStore, [
      'saveAddress',
      'resetAddress',
      'setCustomer',
      'resetCustomer',
    ]),

    /**
     * Reset Billing address if billing address is not the same as shipping address
     */
    toggleBillingIsShippingAddress() {
      if (this.billingIsShipping) {
        this.saveAddress('billing', { address: this.address.shipping });
        this.setCustomer('billing', this.address.customer.shipping);
      } else {
        this.resetAddress('billing');
        this.resetCustomer('billing');
      }
    },
  },
};
</script>
