import { postcodeValidator, postcodeValidatorExistsForCountry } from 'postcode-validator';

export const isValidEmailAddress = (emailAddress: string) => {
  const regex =
    /^((([!#$%&'*+\-/=?^_`{|}~\w])|([!#$%&'*+\-/=?^_`{|}~\w][!#$%&'*+\-/=?^_`{|}~\.\w]{0,}[!#$%&'*+\-/=?^_`{|}~\w]))[@]\w+([-.]\w+)*\.\w+([-.]\w+)*)$/; // https://www.regexlib.com/REDetails.aspx?regexp_id=2558

  return regex.test(emailAddress);
};

export const isValidPhoneNumber = (phoneNumber: string) => {
  const regex = /^(?:\+?\W*)?(?:\W*\d){11,12}\W*$/;

  return regex.test(phoneNumber);
};

export const isValidPostcode = (postcode: string, country) => {
  return  postcodeValidatorExistsForCountry(country) ? postcodeValidator(postcode, country) : false;
}