<template>
  <div class="product-issue-wrapper">
    <div class="product-issue-content">
      <slot></slot>

      <button
        v-if="outboundLinkHref"
        type="button"
        class="button button--full-width button--primary outbound-link-btn"
        @click="gotToOutboundLink()"
        data-test="outbound-link-btn"
      >
        Discover similar products
      </button>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'pinia';
import { useMainStore } from '@/stores/MainStore';

export default {
  name: 'ProductIssue',
  props: {
    outboundLinkHref: {
      type: String,
    },
  },
  methods: {
    ...mapActions(useMainStore, ['closeCheckout']),

    gotToOutboundLink() {
      window.open(this.outboundLinkHref, '_blank');

      this.closeCheckout();
    },
  },
};
</script>

<style lang="scss" scoped>
@import './styles.scss';
</style>
