export default {
  PAYMENT_PROCESSING_COUNTRY_CODE: 'GB',
  ALLOWED_SHIPPING_COUNTRY_CODES: ['GB'],
  PAYMENT_BTN_INITIALISATION_CHECK_TIMEOUT: 10000, // ms
  PAYMENT_POPUP_WINDOW: {
    name: 'shopthru-payment-window',
    isPaymentPopupLaunchIdentifier: 'isPaymentPopup',
  },
  APPLE_PAY_REQUIRED_VERSION: {
    braintree: 3,
    adyen: 9999, // TODO
    stripe: 9999, // TODO
    ppcp: 4,
  },
} as const;
