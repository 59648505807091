<template>
  <svg
    class="icon-cvv"
    width="25"
    height="20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M20.908 1.823H4.295c-.983 0-1.78.796-1.78 1.777v11.85c0 .98.797 1.777 1.78 1.777h16.613c.983 0 1.78-.796 1.78-1.778V3.6c0-.981-.797-1.777-1.78-1.777ZM4.295.046A3.557 3.557 0 0 0 .735 3.6v11.85a3.557 3.557 0 0 0 3.56 3.554h16.613a3.557 3.557 0 0 0 3.56-3.555V3.6a3.557 3.557 0 0 0-3.56-3.554H4.295Z"
      fill="#A7A7A7"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M23.578 8.044H1.922V4.489h21.656v3.555ZM12.602 13.535a1.823 1.823 0 0 1-1.823 1.823H5.662a1.823 1.823 0 0 1 0-3.646h5.117c1.006 0 1.823.816 1.823 1.823Z"
      fill="#A7A7A7"
    />
    <path
      d="M20.864 13.535c0 .73-.592 1.323-1.323 1.323h-2.196a1.323 1.323 0 0 1 0-2.646h2.197c.73 0 1.322.592 1.322 1.323Z"
      stroke="#FF4A4A"
    />
  </svg>
</template>