const isBraintreePaymentAvailable = (
  clientInstance: ToDo,
  paymentType: 'androidPay' | 'applePayWeb' | 'paypal',
) => {
  const config = clientInstance.getConfiguration();

  return !!config.gatewayConfiguration[paymentType];
};

export const BraintreePaymentAvailability = {
  isBraintreePaymentAvailable,
};
