<template>
  <div class="loader-container">
    <div
      :class="{
        'loading-logo': true,
        'loading-logo--slide-up': isEmbeddedMode || !templateType,
        'loading-logo--fade-in': !isEmbeddedMode && templateType === 'PILOT_BLACK_FRIDAY_NOV_24',
      }"
    >
      <!-- Publisher logo -->
      <div class="loading-logo__top logo-containers">
        <img
          v-if="publisherLogo && showPublisherLogoOnLoad"
          tabindex="0"
          :src="publisherLogo"
          :alt="publisherName + ' Logo'"
        />

        <span v-else-if="publisherName && showPublisherLogoOnLoad">{{ publisherName }}</span>

        <div
          v-if="(publisherLogo || publisherName) && showPublisherLogoOnLoad"
          class="vertical-line"
        ></div>

        <img v-if="logo" tabindex="0" :src="logo" :alt="companyName + ' Logo'" />
        <span v-else-if="companyName">{{ companyName }}</span>
      </div>

      <!-- Retailer logo and Shopthru intro -->
      <div
        class="loading-logo__bottom name-containers"
        :class="
          (publisherLogo || publisherName) && showPublisherLogoOnLoad ? 'text-right-align' : ''
        "
      >
        <span v-if="companyName" v-text="'Buy directly from ' + companyName"></span>

        <div v-if="templateType === 'PILOT_BLACK_FRIDAY_NOV_24'" class="font-size-xs pt-10">
          Searching for discounts

          <!-- Loading dots -->
          <span class="dots-loading">
            <span class="loading__letter">.</span>
            <span class="loading__letter">.</span>
            <span class="loading__letter">.</span>
          </span>
        </div>

        <span v-else class="shopthru-powered">Powered by <span>Shopthru Limited</span></span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'pinia';
import { useMainStore } from '@/stores/MainStore';
import { useAdvertiserStore } from '@/stores/AdvertiserStore';
import { usePublisherStore } from '@/stores/PublisherStore';

export default {
  name: 'SplashScreen',
  computed: {
    ...mapState(useMainStore, ['templateType', 'isEmbeddedMode']),
    ...mapState(useAdvertiserStore, ['logo', 'companyName']),
    ...mapState(usePublisherStore, ['publisherLogo', 'publisherName', 'showPublisherLogoOnLoad']),
  },
};
</script>
