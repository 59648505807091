<template>
  <div class="promo-code-container" ref="mainDeviceContainer">
    <div
      class="button-background"
      ref="buttonBackground"
      :class="{
        'button-background--complete': ![null, 'LOOKUP_IN_PROGRESS'].includes(discountLookupState),
        'discount-loading': discountLookupState === 'LOOKUP_IN_PROGRESS',
        'discount-applied': discountLookupState === 'DISCOUNT_APPLIED',
        'discount-not-applicable': discountLookupState === 'DISCOUNT_NOT_APPLICABLE',
        'discount-revoked': discountLookupState === 'DISCOUNT_REVOKED',
        'discount-erred': discountLookupState === 'LOOKUP_ERRED',
      }"
    >
      <div class="text-part-discount" ref="textPartDiscount" data-test="swiper-text">
        <!-- Discounted price text -->
        <div v-if="['DISCOUNT_APPLIED', 'DISCOUNT_NOT_APPLICABLE'].includes(discountLookupState)">
          <!-- A discount was successfully applied -->
          <div v-if="discountLookupState === 'DISCOUNT_APPLIED'" class="discount-info">
            <span class="font-weight-bold">Discounts found&nbsp;</span>

            <span class="font-weight-bold"
              >You save {{ getFormattedPrice(Math.abs(cart.pricing.totalDiscount)) }}</span
            >
          </div>

          <!-- A discount was not available, the best price was already found -->
          <div v-if="discountLookupState === 'DISCOUNT_NOT_APPLICABLE'" class="discount-info">
            <span class="font-weight-bold">You already have the&nbsp;best price</span>
          </div>
        </div>

        <!-- Alternative messaging for when discount was not applied -->
        <span v-else class="slide-text" ref="slideText" v-html="discountDeviceText" />

        <div class="progress-block" ref="progressBlock">
          <div
            v-if="discountLookupState === 'LOOKUP_IN_PROGRESS'"
            class="progress-block-text"
          >
            <span v-text="discountDeviceText"></span>
            <!-- Loading dots -->
            <div class="dots-loading" v-if="discountLookupState === 'LOOKUP_IN_PROGRESS'">
              <span class="loading__letter">.</span>
              <span class="loading__letter">.</span>
              <span class="loading__letter">.</span>
            </div>
          </div>
        </div>
      </div>

      <div class="slider-toggle-button" ref="slider">
        <span class="button-text">
          <!-- Padlock Icon -->
          <svg
              v-if="discountLookupState === null"
              width="15"
              height="18"
              viewBox="0 0 15 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
          >
            <rect x="0.678223" y="7.18018" width="14.0773" height="9.91491" rx="1.40305" fill="white"/>
            <path d="M7.7418 0.772949C5.33965 0.772949 3.39233 2.72027 3.39233 5.12241V8.44297L12.0913 8.74696V5.12241C12.0913 2.72027 10.1439 0.772949 7.7418 0.772949Z" stroke="white" stroke-width="1.40305"/>
          </svg>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import loadingAnimation from '@/assets/lottie/loadingdots.json';
import successAnimation from '@/assets/lottie/success.json';
import { mapState, mapWritableState, mapActions } from 'pinia';
import { useProductStore } from '@/stores/ProductStore';
import { useCartStore } from '@/stores/CartStore';
import { ref } from '@vue/reactivity';

export default {
  name: 'PromoCode',
  props: {
    doReloadComponent: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      initialMouse: 0,
      slideMovementTotal: 0,
      mouseIsDown: false,
      currentMouse: 0,
      relativeMouse: 0,
      slider: ref(null),
      mainDeviceContainer: ref(null),
      buttonBackground: ref(null),
      slideText: ref(null),
      locker: ref(null),
      progressBlock: ref(null),
      textPartDiscount: ref(null),
    };
  },
  computed: {
    ...mapState(useCartStore, ['cart', 'getFormattedPrice', 'isDiscountRevoked']),
    ...mapWritableState(useProductStore, ['discountLookupState']),
    successAnimation() {
      return successAnimation;
    },
    loadingAnimation() {
      return loadingAnimation;
    },
    discountDeviceText() {
      // Loading state
      if (this.discountLookupState === 'LOOKUP_IN_PROGRESS') {
        return 'Searching for your discount';
      }

      // Lookup erred
      if (this.discountLookupState === 'LOOKUP_ERRED') {
        return 'Sorry there was a problem fetching your discount, <a href="#">try again.</a>';
      }

      // Discount revoked
      if (this.discountLookupState === 'DISCOUNT_REVOKED') {
        return 'A discount is not applicable to your current basket selection';
      }

      // Default state
      return 'UNLOCK DISCOUNTS';
    },
    isDeviceInteractive() {
      // Whether or not the user can swipe the swiper
      return [null, 'LOOKUP_ERRED'].includes(this.discountLookupState);
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.initDragListeners();

      const classList = this.$refs.buttonBackground.classList;

      if (
        classList.contains('button-background--complete') ||
        classList.contains('button-background--unlocked')
      ) {
        this.slideMovementTotal =
          this.$refs.buttonBackground.offsetWidth - this.$refs.slider.offsetWidth + 10;

        this.$refs.slider.style.left = this.slideMovementTotal - 10 + 'px';
      }
    });
  },
  beforeUnmount() {
    document.body.removeEventListener('mousemove', this.mouseMove);
    document.body.removeEventListener('touchmove', this.mouseMove);
    document.body.removeEventListener('mouseup', this.mouseUp);
  },
  methods: {
    ...mapActions(useCartStore, ['setDiscounted']),

    initDragListeners() {
      document.body.addEventListener('mousemove', this.mouseMove);
      document.body.addEventListener('touchmove', this.mouseMove);
      document.body.addEventListener('mouseup', this.mouseUp);
      window.addEventListener('resize', this.windowResize, true);

      this.$refs.mainDeviceContainer.addEventListener('touchstart', this.mouseDown);
      this.$refs.mainDeviceContainer.addEventListener('touchend', this.mouseUp);
      this.$refs.mainDeviceContainer.addEventListener('mousedown', this.mouseDown);
      this.$refs.mainDeviceContainer.addEventListener('mouseup', this.mouseUp);
    },

    calculateSlideMovementTotal() {
      return (this.slideMovementTotal =
        this.$refs.buttonBackground.offsetWidth - this.$refs.slider.offsetWidth + 10);
    },

    mouseDown(e) {
      e.preventDefault(); // Required to prevent the 'swipe-back' gesture on mobile when the Discount device is being swiped.

      if (this.discountLookupState === 'LOOKUP_ERRED') {
        this.doReloadComponent();
      }

      this.mouseIsDown = true;
      this.calculateSlideMovementTotal();
      this.initialMouse = event.clientX || event.touches[0].pageX;
    },

    windowResize() {
      if (
        [
          'DISCOUNT_APPLIED',
          'LOOKUP_ERRED',
          'DISCOUNT_NOT_APPLICABLE',
          'LOOKUP_IN_PROGRESS',
        ].includes(this.discountLookupState)
      ) {
        this.calculateSlideMovementTotal();
        this.animateSlider(this.$refs.slider, this.slideMovementTotal - 10, 0);
        return;
      }

      this.animateSlider(this.$refs.slider, 8, 0);
    },

    mouseUp(event) {
      if (!this.mouseIsDown || !this.isDeviceInteractive) {
        return;
      }

      this.mouseIsDown = false;
      this.$refs.progressBlock.style.width = 0 + 'px';

      const self = this;

      this.fade(this.$refs.slideText, 1, 300);
      this.fade(this.$refs.progressBlock, 1, 300);
      this.animateSlider(this.$refs.slider, this.slideMovementTotal + 40, 300);

      setTimeout(function () {
        self.$refs.buttonBackground.classList.add('button-background--unlocked');
        self.setDiscounted();
      }, 300);
    },

    mouseMove(event) {
      if (!this.mouseIsDown || !this.isDeviceInteractive) {
        return;
      }

      const currentMouse = event.clientX || event.touches[0].pageX;
      const relativeMouse = currentMouse - this.initialMouse;
      const slidePercent = 1 - relativeMouse / this.slideMovementTotal;

      this.fade(this.$refs.slideText, slidePercent, 0);
      this.fade(this.$refs.progressBlock, 1 - slidePercent, 300);

      if (relativeMouse <= 0) {
        this.setSliderPosition(this.$refs.slider, 0);
        return;
      }
      if (relativeMouse >= this.slideMovementTotal) {
        this.setSliderPosition(this.$refs.slider, this.slideMovementTotal - 10);
        this.fade(this.$refs.slideText, 1, 0);
        return;
      }

      this.setSliderPosition(this.$refs.slider, relativeMouse - 10);
    },

    animateSlider(element, left, duration) {
      element.style.transition = 'left ' + duration + 'ms';
      this.$refs.progressBlock.style.transition = 'all' + duration + 'ms';

      element.style.left = left + 'px';
      this.$refs.progressBlock.style.width = left + 'px';
    },

    animateProgressBar(element, left, duration) {
      element.style.transition = 'width ' + duration + 'ms';
      element.style.width = left + 'px';
    },

    fade(element, opacity, duration) {
      element.style.transition = 'opacity ' + duration + 'ms';
      element.style.opacity = opacity;
    },

    setSliderPosition(element, left) {
      element.style.left = left + 'px';
      element.style.transition = 'left 0ms';
      this.$refs.progressBlock.style.width = left + 'px';
    },
  },
};
</script>

<style lang="scss" scoped>
@import './styles.scss';
</style>
