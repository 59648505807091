import { jwtDecode } from 'jwt-decode';
import type { CartJwtPayload } from '@/types/cart.types';

export const decodeJwt = <T>(jwt: CartJwtPayload): T => {
  let decodedToken: T | null = null;

  try {
    decodedToken = jwtDecode<T>(jwt);
  } catch (err) {
    console.error('Unable to decode JWT!', err);
    throw err;
  }

  return decodedToken;
};
