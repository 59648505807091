<template>
  <ProductIssue :outboundLinkHref="getFallbackUrl">
    <div class="product-not-found-wrapper">
      <div class="icon">
        <lottie-animation :animationData="errorAnimation" :auto-play="true" />
      </div>

      <p>Oops, there seems to be an issue and we can't find what you're looking for</p>
    </div>
  </ProductIssue>
</template>

<script>
import { mapState } from 'pinia';
import { useMainStore } from '@/stores/MainStore';
import ProductIssue from '@/components/ProductIssue/ProductIssue.vue';
import errorAnimation from '@/assets/lottie/error.json';

export default {
  name: 'ProductNotFound',
  components: {
    ProductIssue,
  },
  computed: {
    ...mapState(useMainStore, ['getFallbackUrl']),

    errorAnimation() {
      return errorAnimation;
    },
  },
};
</script>

<style lang="scss" scoped>
@import './ProductNotFound.scss';
</style>
