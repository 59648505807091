<template>
  <transition key="sticky" appear mode="out-in">
    <div class="sticky-product-summary" v-show="isShowStickyProductSummary">
      <!-- Product name and quantity -->
      <div class="sticky-product-summary__name-quantity">
        <span
          ><strong>{{ cart?.productQuantity + ' x ' }}</strong
          >{{ product.name }}</span
        >
      </div>

      <div class="sticky-product-summary__pricing-pay-now">
        <!-- Price -->
        <span v-if="cart?.pricing" v-text="getFormattedPrice(getTotalCartPrice)"></span>

        <!-- Buy now button -->
        <InPageBuyNowBtn v-show="isShowStickyProductSummaryExtended" class="pay-now-btn" />
      </div>
    </div>
  </transition>
</template>

<script>
import { mapState } from 'pinia';
import { useProductStore } from '@/stores/ProductStore';
import { useCartStore } from '@/stores/CartStore';
import InPageBuyNowBtn from '@/components/Product/InPageBuyNowBtn.vue';

export default {
  name: 'StickyProductSummary',
  components: {
    InPageBuyNowBtn,
  },
  computed: {
    ...mapState(useProductStore, [
      'product',
      'isShowStickyProductSummary',
      'isShowStickyProductSummaryExtended',
    ]),
    ...mapState(useCartStore, ['cart', 'getFormattedPrice', 'getTotalCartPrice']),
  },
};
</script>

<style lang="scss" scoped>
@import './styles.scss';
</style>
