const sortVariantsAlphbetically = (variants) =>
  variants.sort((a: string, b: string) => {
    return a.variantCode.toLowerCase().localeCompare(b.variantCode.toLowerCase());
  });

/**
 * Create a string that uniquely identifies a product which supplied options, e.g. `size=small_colour_1`.
 */
export const getOptionedProductHash = (
  variants: {
    variantCode: string;
    val: string;
  }[],
) =>
  sortVariantsAlphbetically(variants)
    .map((v) => `${v.variantCode}=${v.val}`)
    .join('|');
