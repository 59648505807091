<template>
  <div class="copyright">
    <span class="secondary-text">© 2024 Shopthru Limited</span>
  </div>
</template>

<script>
export default {
  name: 'Copyright',
};
</script>
