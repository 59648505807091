<template>
  <div class="promo-code-container" ref="mainDeviceContainer">
    <div
      class="button-background"
      ref="buttonBackground"
      :class="{
        'button-background--complete': ![null, 'LOOKUP_IN_PROGRESS'].includes(discountLookupState),
        'discount-loading': discountLookupState === 'LOOKUP_IN_PROGRESS',
        'discount-applied': discountLookupState === 'DISCOUNT_APPLIED',
        'discount-not-applicable': discountLookupState === 'DISCOUNT_NOT_APPLICABLE',
        'discount-revoked': discountLookupState === 'DISCOUNT_REVOKED',
        'discount-erred': discountLookupState === 'LOOKUP_ERRED',
      }"
    >
      <div class="text-part-discount" ref="textPartDiscount" data-test="swiper-text">
        <!-- Discounted price text -->
        <div v-if="['DISCOUNT_APPLIED', 'DISCOUNT_NOT_APPLICABLE'].includes(discountLookupState)">
          <!-- A discount was successfully applied -->
          <div v-if="discountLookupState === 'DISCOUNT_APPLIED'" class="discount-info">
            <span class="font-weight-light">Best price found! You get&nbsp;</span>

            <span class="font-weight-bold"
              >{{ getFormattedPrice(Math.abs(cart.pricing.totalDiscount)) }} OFF</span
            >
          </div>

          <!-- A discount was not available, the best price was already found -->
          <div v-if="discountLookupState === 'DISCOUNT_NOT_APPLICABLE'" class="discount-info">
            <span class="font-weight-light">You already have the&nbsp;</span>

            <span class="font-weight-bold">best price</span>
          </div>
        </div>

        <!-- Alternative messaging for when discount was not applied -->
        <span v-else class="slide-text" ref="slideText" v-html="discountDeviceText" />

        <div class="progress-block" ref="progressBlock">
          <div
            v-if="discountLookupState === 'LOOKUP_IN_PROGRESS'"
            class="progress-block-text"
            v-text="discountDeviceText"
          />
        </div>
      </div>

      <div class="slider-toggle-button" ref="slider">
        <span class="button-text">
          <!-- Forwards arrows (>>) -->
          <svg
            v-if="discountLookupState === null"
            width="21"
            height="28"
            viewBox="0 0 21 28"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M7.69373 0.875136C7.19279 0.391882 6.38062 0.391882 5.87968 0.875136C5.37875 1.35839 5.37875 2.1419 5.87968 2.62516L17.6709 14.0002L5.87967 25.3752C5.37874 25.8585 5.37874 26.642 5.87967 27.1253C6.38061 27.6085 7.19278 27.6085 7.69372 27.1253L20.3798 14.8869L20.392 14.8753C20.6331 14.6427 20.7581 14.3407 20.7672 14.036C20.7687 13.9845 20.767 13.933 20.7619 13.8817C20.7345 13.6055 20.6112 13.3366 20.392 13.1251L20.3807 13.1144L7.69373 0.875136ZM3.15862 5.24965C2.65768 4.76639 1.84551 4.76639 1.34457 5.24965C0.843639 5.7329 0.843638 6.51641 1.34457 6.99966L8.60096 13.9999L1.34458 21.0002C0.843643 21.4835 0.843644 22.267 1.34458 22.7502C1.84551 23.2335 2.65769 23.2335 3.15862 22.7502L11.3218 14.8751C11.5772 14.6287 11.7024 14.3043 11.6974 13.9814C11.6926 13.6708 11.5674 13.3617 11.3218 13.1247L3.15862 5.24965Z"
              fill="white"
            />
          </svg>
        </span>

        <div class="locker" ref="locker">
          <!-- Loading dots -->
          <div class="dots-loading" v-if="discountLookupState === 'LOOKUP_IN_PROGRESS'">
            <span class="loading__letter">.</span>
            <span class="loading__letter">.</span>
            <span class="loading__letter">.</span>
          </div>

          <!-- Success tick -->
          <div v-if="['DISCOUNT_APPLIED', 'DISCOUNT_NOT_APPLICABLE'].includes(discountLookupState)">
            <svg
              width="51"
              height="54"
              viewBox="0 0 49 48"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M41.9717 41.1404C46.6648 36.5618 49.0198 30.8595 49.0198 24C49.0198 17.1404 46.6309 11.2369 41.8531 6.74216C37.0753 2.24742 31.3825 6.28079e-06 24.7749 6.56962e-06C18.1673 6.85844e-06 12.2713 2.28097 7.57816 6.85958C2.88506 11.4382 0.530028 17.1404 0.530028 24C0.530029 30.8595 2.91894 36.7631 7.69676 41.2578C12.4746 45.7526 18.1673 48 24.7749 48C31.3826 48 37.2786 45.719 41.9717 41.1404Z"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M36.8641 18.1321C37.3473 17.6312 37.3473 16.819 36.8641 16.3181C36.3808 15.8171 35.5973 15.8171 35.1141 16.3181L22.6254 29.2637L15.6252 22.0073C15.1419 21.5063 14.3584 21.5063 13.8751 22.0073C13.3919 22.5082 13.3919 23.3204 13.8751 23.8213L21.7502 31.9845C21.9758 32.2184 22.2669 32.3431 22.5623 32.3586C22.8998 32.3764 23.2429 32.2517 23.5006 31.9845L36.8641 18.1321Z"
                fill="white"
              />
            </svg>
          </div>

          <!-- Exclamation mark -->
          <div v-else-if="['DISCOUNT_REVOKED', 'LOOKUP_ERRED'].includes(discountLookupState)">
            <svg
              width="50"
              height="48"
              viewBox="0 0 50 48"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M41.9716 41.1404C46.6648 36.5618 49.0198 30.8595 49.0198 24C49.0198 17.1404 46.6309 11.2369 41.853 6.74216C37.0752 2.24742 31.3825 6.28079e-06 24.7749 6.56962e-06C18.1673 6.85844e-06 12.2712 2.28097 7.57813 6.85958C2.88503 11.4382 0.529997 17.1404 0.529998 24C0.529998 30.8595 2.91891 36.7631 7.69673 41.2578C12.4746 45.7526 18.1673 48 24.7749 48C31.3825 48 37.2785 45.719 41.9716 41.1404Z"
              />
              <path d="M23 13H26V28.6782H23V13Z" fill="white" />
              <path d="M23 31.4598H26V35H23V31.4598Z" fill="white" />
            </svg>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import loadingAnimation from '@/assets/lottie/loadingdots.json'; // TODO is this import required?
import successAnimation from '@/assets/lottie/success.json';
import { mapState, mapWritableState, mapActions } from 'pinia';
import { useProductStore } from '@/stores/ProductStore';
import { useCartStore } from '@/stores/CartStore';
import { ref } from '@vue/reactivity';

export default {
  name: 'PromoCode',
  props: {
    doReloadComponent: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      initialMouse: 0,
      slideMovementTotal: 0,
      mouseIsDown: false,
      currentMouse: 0,
      relativeMouse: 0,
      slider: ref(null),
      mainDeviceContainer: ref(null),
      buttonBackground: ref(null),
      slideText: ref(null),
      locker: ref(null),
      progressBlock: ref(null),
      textPartDiscount: ref(null),
    };
  },
  computed: {
    ...mapState(useCartStore, ['cart', 'getFormattedPrice', 'isDiscountRevoked']),
    ...mapWritableState(useProductStore, ['discountLookupState']),
    successAnimation() {
      return successAnimation;
    },
    loadingAnimation() {
      // TODO this is not referenced?
      return loadingAnimation;
    },
    discountDeviceText() {
      // Loading state
      if (this.discountLookupState === 'LOOKUP_IN_PROGRESS') {
        return 'Searching for your discount.';
      }

      // Lookup erred
      if (this.discountLookupState === 'LOOKUP_ERRED') {
        return 'Sorry there was a problem fetching your discount, <a href="#">try again.</a>';
      }

      // Discount revoked
      if (this.discountLookupState === 'DISCOUNT_REVOKED') {
        return 'A discount is not applicable to your current basket selection';
      }

      // Default state
      return this.$isScreenSizeAtLeast('m') ? 'Click for discounts' : 'Swipe for discounts';
    },
    isDeviceInteractive() {
      // Whether or not the user can swipe the swiper
      return [null, 'LOOKUP_ERRED'].includes(this.discountLookupState);
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.initDragListeners();

      const classList = this.$refs.buttonBackground.classList;

      if (
        classList.contains('button-background--complete') ||
        classList.contains('button-background--unlocked')
      ) {
        this.slideMovementTotal =
          this.$refs.buttonBackground.offsetWidth - this.$refs.slider.offsetWidth + 10;

        this.$refs.slider.style.left = this.slideMovementTotal - 10 + 'px';
      }
    });
  },
  beforeUnmount() {
    document.body.removeEventListener('mousemove', this.mouseMove);
    document.body.removeEventListener('touchmove', this.mouseMove);
    document.body.removeEventListener('mouseup', this.mouseUp);
  },
  methods: {
    ...mapActions(useCartStore, ['setDiscounted']),

    initDragListeners() {
      document.body.addEventListener('mousemove', this.mouseMove);
      document.body.addEventListener('touchmove', this.mouseMove);
      document.body.addEventListener('mouseup', this.mouseUp);
      window.addEventListener('resize', this.windowResize, true);

      this.$refs.mainDeviceContainer.addEventListener('touchstart', this.mouseDown);
      this.$refs.mainDeviceContainer.addEventListener('touchend', this.mouseUp);
      this.$refs.mainDeviceContainer.addEventListener('mousedown', this.mouseDown);
      this.$refs.mainDeviceContainer.addEventListener('mouseup', this.mouseUp);
    },

    calculateSlideMovementTotal() {
      return (this.slideMovementTotal =
        this.$refs.buttonBackground.offsetWidth - this.$refs.slider.offsetWidth + 10);
    },

    mouseDown(e) {
      e.preventDefault(); // Required to prevent the 'swipe-back' gesture on mobile when the Discount device is being swiped.

      if (this.discountLookupState === 'LOOKUP_ERRED') {
        this.doReloadComponent();
      }

      this.mouseIsDown = true;
      this.calculateSlideMovementTotal();
      this.initialMouse = event.clientX || event.touches[0].pageX;
    },

    windowResize() {
      if (
        [
          'DISCOUNT_APPLIED',
          'LOOKUP_ERRED',
          'DISCOUNT_NOT_APPLICABLE',
          'LOOKUP_IN_PROGRESS',
        ].includes(this.discountLookupState)
      ) {
        this.calculateSlideMovementTotal();
        this.animateSlider(this.$refs.slider, this.slideMovementTotal - 10, 0);
        return;
      }

      this.animateSlider(this.$refs.slider, 0, 0);
    },

    mouseUp(event) {
      if (!this.mouseIsDown || !this.isDeviceInteractive) {
        return;
      }

      this.mouseIsDown = false;
      this.$refs.progressBlock.style.width = 0 + 'px';

      const self = this;

      this.fade(this.$refs.slideText, 1, 300);
      this.fade(this.$refs.progressBlock, 1, 300);
      this.animateSlider(this.$refs.slider, this.slideMovementTotal - 10, 300);

      setTimeout(function () {
        self.$refs.buttonBackground.classList.add('button-background--unlocked');
        self.setDiscounted();
      }, 300);
    },

    mouseMove(event) {
      if (!this.mouseIsDown || !this.isDeviceInteractive) {
        return;
      }

      const currentMouse = event.clientX || event.touches[0].pageX;
      const relativeMouse = currentMouse - this.initialMouse;
      const slidePercent = 1 - relativeMouse / this.slideMovementTotal;

      this.fade(this.$refs.slideText, slidePercent, 0);
      this.fade(this.$refs.progressBlock, 1 - slidePercent, 300);

      if (relativeMouse <= 0) {
        this.setSliderPosition(this.$refs.slider, 0);
        return;
      }
      if (relativeMouse >= this.slideMovementTotal) {
        this.setSliderPosition(this.$refs.slider, this.slideMovementTotal - 10);
        this.fade(this.$refs.slideText, 1, 0);
        return;
      }

      this.setSliderPosition(this.$refs.slider, relativeMouse - 10);
    },

    animateSlider(element, left, duration) {
      element.style.transition = 'left ' + duration + 'ms';
      this.$refs.progressBlock.style.transition = 'all' + duration + 'ms';

      element.style.left = left + 'px';
      this.$refs.progressBlock.style.width = left + 'px';
    },

    animateProgressBar(element, left, duration) {
      element.style.transition = 'width ' + duration + 'ms';
      element.style.width = left + 'px';
    },

    fade(element, opacity, duration) {
      element.style.transition = 'opacity ' + duration + 'ms';
      element.style.opacity = opacity;
    },

    setSliderPosition(element, left) {
      element.style.left = left + 'px';
      element.style.transition = 'left 0ms';
      this.$refs.progressBlock.style.width = left + 'px';
    },
  },
};
</script>

<style lang="scss" scoped>
@import './styles.scss';
</style>
