// @param string formattedPrice - Supply as e.g. £123.45
const splitFormattedPrice = (
  formattedPrice: string,
): {
  currency: string;
  whole: string;
  remainder: string;
} => {
  return {
    currency: formattedPrice[0],
    whole: formattedPrice.slice(1, formattedPrice.indexOf('.')),
    remainder: formattedPrice.split('.')[1],
  };
};

export default {
  splitFormattedPrice,
};
