<template>
  <dl
    class="product-features-tab tab-panel"
    id="product-features-tab"
    :class="isTabOpen ? 'tab-open' : 'tab-closed'"
  >
    <dt
      class="tab-trigger"
      aria-labelledby="product-features-h4"
      tabindex="0"
      role="button"
      @keydown.enter="
        toggleTab();
        readMore = false;
      "
      @click="
        toggleTab();
        readMore = false;
      "
    >
      <h4 id="product-features-h4">Product Features</h4>
      <IconArrow />
    </dt>
    <dd class="tab-content" :class="readMore && isTabOpen ? 'read-open' : ''">
      <HorizontalTable class="mt-15" :cells="getFeatureCells(product.displayAttributes)" />

      <button
        v-if="displayedTextLength"
        tabindex="1"
        class="button button--full-width read-more-btn"
        @click="readMore = !readMore"
      >
        <span v-if="readMore">Read Less</span>
        <span v-else>Read More</span>
      </button>
    </dd>
  </dl>
</template>

<script>
// Stores
import { mapState, mapWritableState } from 'pinia';
import { useProductStore } from '@/stores/ProductStore.ts';
import IconArrow from '@/components/icons/IconArrow.vue';
import HorizontalTable from '@/components/Tables/HorizontalTable.vue';
import { useMainStore } from '@/stores/MainStore';

export default {
  name: 'ProductFeatures',
  components: {
    IconArrow,
    HorizontalTable,
  },
  computed: {
    ...mapState(useProductStore, ['product']),
    ...mapWritableState(useMainStore, ['currentlyOpenInfoTab']),

    displayedTextLength() {
      return this.product.displayAttributes?.length >= 8;
    },

    isTabOpen() {
      return this.currentlyOpenInfoTab === 'PRODUCT_FEATURES';
    },
  },
  data: () => ({
    readMore: false,
  }),
  methods: {
    toggleTab() {
      if (this.currentlyOpenInfoTab === 'PRODUCT_FEATURES') {
        this.currentlyOpenInfoTab = undefined;
      } else {
        this.currentlyOpenInfoTab = 'PRODUCT_FEATURES';
      }
    },
    getFeatureCells(featureList) {
      return featureList.reduce(
        (acc, cur) => {
          const isCellHasContent = !!(cur.value && cur.value.trim().length);

          if (isCellHasContent) {
            acc.headers.push(cur.name);
            acc.content.push(cur.value);
          }

          return acc;
        },
        {
          headers: [],
          content: [],
        },
      );
    },
  },
};
</script>
