<template>
  <button type="button" class="button button--primary button--xs" @click="scrollToPaymentButtons">
    Buy now
  </button>
</template>

<script>
import { mapActions } from 'pinia';
import { polyfill, elementScrollIntoView } from 'seamless-scroll-polyfill';
import { useProductStore } from '@/stores/ProductStore';

export default {
  name: 'AccordionBuyNowCta',
  methods: {
    ...mapActions(useProductStore, ['closeGalleryOverlay']),

    async scrollToPaymentButtons() {
      polyfill();

      // There is an instance of this button in the Gallery overlay.
      // It's simplest to just make sure the overlay is closed here in all events - DS.
      this.closeGalleryOverlay();

      elementScrollIntoView(document.getElementById('app'), { behavior: 'smooth' });
    },
  },
};
</script>
