<template>
  <svg
    class="icon-error"
    width="20"
    height="21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M10 .709c-5.52 0-10 4.48-10 10s4.48 10 10 10 10-4.48 10-10-4.48-10-10-10Zm1 15H9v-2h2v2Zm0-4H9v-6h2v6Z"
      fill="#FF4A4A"
    />
  </svg>
</template>
