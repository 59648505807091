<template>
  <div>
    <form>
      <AddressForm addressType="shipping" key="shipping" />
    </form>
  </div>
</template>

<script>
// Components
import AddressForm from './../Form/index.vue';

export default {
  name: 'ShippingForm',
  components: {
    AddressForm,
  },
};
</script>
