<template>
  <dl class="product-details-tab tab-panel" :class="isTabOpen ? 'tab-open' : 'tab-closed'">
    <dt
      class="tab-trigger"
      tabindex="0"
      role="button"
      @keydown.enter="toggleTab()"
      @click="toggleTab()"
    >
      <h4>Shipping &amp; Returns</h4>
      <IconArrow />
    </dt>
    <dd class="tab-content">
      <p v-text="advertiserDelivery"></p>
    </dd>
  </dl>
</template>

<script>
// Stores
import { mapState, mapWritableState } from 'pinia';
import { useAdvertiserStore } from '@/stores/AdvertiserStore';
import IconArrow from '@/components/icons/IconArrow.vue';
import { useMainStore } from '@/stores/MainStore';

export default {
  name: 'ProductDelivery',
  components: { IconArrow },
  computed: {
    ...mapState(useAdvertiserStore, ['advertiserDelivery']),
    ...mapWritableState(useMainStore, ['currentlyOpenInfoTab']),

    isTabOpen() {
      return this.currentlyOpenInfoTab === 'PRODUCT_DELIVERY';
    },
  },
  data: () => ({
    tabOpen: false,
  }),
  methods: {
    toggleTab() {
      if (this.currentlyOpenInfoTab === 'PRODUCT_DELIVERY') {
        this.currentlyOpenInfoTab = undefined;
      } else {
        this.currentlyOpenInfoTab = 'PRODUCT_DELIVERY';
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import './styles.scss';
</style>
