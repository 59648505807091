export const paymentButtonTypes = ['card', 'googlepay', 'applepay', 'paypal'] as const;
export const paymentProviderTypes = ['braintree', 'stripe', 'adyen', 'ppcp'] as const;

export type PaymentButtonType = (typeof paymentButtonTypes)[number];
export type PaymentProviderType = (typeof paymentProviderTypes)[number];

export type PaymentButtonInitialisationStateType =
  | null
  | 'NOT_ENABLED'
  | 'NOT_SUPPORTED'
  | 'INITIALISED';
