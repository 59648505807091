<template>
  <svg
    width="9px"
    height="9px"
    viewBox="0 0 9 9"
    id="svg8"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:cc="http://creativecommons.org/ns#"
    xmlns:dc="http://purl.org/dc/elements/1.1/"
    xmlns:inkscape="http://www.inkscape.org/namespaces/inkscape"
    xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#"
    xmlns:sodipodi="http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd"
    xmlns:svg="http://www.w3.org/2000/svg"
  >
    <defs id="defs2" />

    <g id="layer1" transform="translate(0,-288.53332)">
      <path
        d="M 16 1 C 12.139297 1 9 4.1392882 9 8 L 9 13 L 7 13 A 1.0000999 1.0000999 0 0 0 6 14 L 6 30 A 1.0000999 1.0000999 0 0 0 7 31 L 25 31 A 1.0000999 1.0000999 0 0 0 26 30 L 26 14 A 1.0000999 1.0000999 0 0 0 25 13 L 23 13 L 23 8 C 23 4.1392882 19.860703 1 16 1 z M 16 3 C 18.787297 3 21 5.212674 21 8 L 21 13 L 11 13 L 11 8 C 11 5.212674 13.212703 3 16 3 z M 16 21.052734 A 0.99999992 0.99999992 0 0 1 17 22.052734 A 0.99999992 0.99999992 0 0 1 16 23.052734 A 0.99999992 0.99999992 0 0 1 15 22.052734 A 0.99999992 0.99999992 0 0 1 16 21.052734 z "
        id="rect864"
        style="
          color: #000000;
          font-style: normal;
          font-variant: normal;
          font-weight: normal;
          font-stretch: normal;
          font-size: medium;
          line-height: normal;
          font-family: sans-serif;
          font-variant-ligatures: normal;
          font-variant-position: normal;
          font-variant-caps: normal;
          font-variant-numeric: normal;
          font-variant-alternates: normal;
          font-feature-settings: normal;
          text-indent: 0;
          text-align: start;
          text-decoration: none;
          text-decoration-line: none;
          text-decoration-style: solid;
          text-decoration-color: #000000;
          letter-spacing: normal;
          word-spacing: normal;
          text-transform: none;
          writing-mode: lr-tb;
          direction: ltr;
          text-orientation: mixed;
          dominant-baseline: auto;
          baseline-shift: baseline;
          text-anchor: start;
          white-space: normal;
          shape-padding: 0;
          clip-rule: nonzero;
          display: inline;
          overflow: visible;
          visibility: visible;
          opacity: 1;
          isolation: auto;
          mix-blend-mode: normal;
          color-interpolation: sRGB;
          color-interpolation-filters: linearRGB;
          solid-color: #000000;
          solid-opacity: 1;
          vector-effect: none;
          fill: #000000;
          fill-opacity: 1;
          fill-rule: nonzero;
          stroke: none;
          stroke-width: 1.99999988;
          stroke-linecap: round;
          stroke-linejoin: round;
          stroke-miterlimit: 4;
          stroke-dasharray: none;
          stroke-dashoffset: 0;
          stroke-opacity: 1;
          paint-order: stroke fill markers;
          color-rendering: auto;
          image-rendering: auto;
          shape-rendering: auto;
          text-rendering: auto;
          enable-background: accumulate;
        "
        transform="matrix(0.26458333,0,0,0.26458333,0,288.53332)"
      />
    </g>
  </svg>
</template>
