<template>
  <div
    :class="{
      prices: true,
      'prices--discount-applied': discountLookupState === 'DISCOUNT_APPLIED',
    }"
  >
    <span class="price">
      <span
        data-test="normal-price"
        v-text="getFormattedPrice(getSelectedProductRegularPriceDisplay)"
      ></span>
    </span>

    <span class="price">
      <span
        v-if="cart?.pricing?.totalDiscountedPrice"
        data-test="discounted-price"
        v-text="getFormattedPrice(cart.pricing.totalDiscountedPrice)"
      ></span>
    </span>
  </div>
</template>

<script>
import { mapActions, mapState } from 'pinia';
import { useProductStore } from '@/stores/ProductStore.ts';
import { useCartStore } from '@/stores/CartStore.ts';

import ProductQty from '@/components/ProductQty/index.vue';
export default {
  name: 'PricesDefault',
  components: {
    ProductQty,
  },
  computed: {
    ...mapState(useProductStore, ['discountLookupState']),
    ...mapState(useCartStore, ['cart', 'getFormattedPrice']),

    getSelectedProductRegularPriceDisplay() {
      return window.Cypress ? 123 : this.getSelectedProductRegularPrice();
    },
  },
  methods: {
    ...mapActions(useCartStore, [
      'incrementQuantity',
      'decrementQuantity',
      'setQuantity',
      'getSelectedProductRegularPrice',
    ]),
  },
};
</script>

<style lang="scss" scoped>
@import './styles.scss';
</style>
