<template>
  <div class="secure-shopping">
    <div class="secure-shopping__icon">
      <PadlockIcon />
    </div>

    <span class="secure-shopping__text">Secure Shopping</span>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import PadlockIcon from '@/components/icons/PadlockIcon.vue';

export default defineComponent({
  name: 'SecureShopping',
  components: { PadlockIcon },
});
</script>

<style lang="scss" scoped>
@import './styles.scss';
</style>
