<template>
  <svg
    class="icon-card"
    width="25"
    height="20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M20.815 2.105H4.227c-.982 0-1.778.796-1.778 1.777v11.85c0 .981.796 1.777 1.778 1.777h16.588c.982 0 1.777-.796 1.777-1.778V3.882c0-.981-.795-1.777-1.777-1.777ZM4.227.328A3.555 3.555 0 0 0 .672 3.882v11.85a3.555 3.555 0 0 0 3.555 3.554h16.588a3.555 3.555 0 0 0 3.555-3.555V3.882A3.555 3.555 0 0 0 20.815.328H4.227Z"
      fill="#A7A7A7"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M23.481 8.326H1.857V4.77H23.48v3.555Z"
      fill="#A7A7A7"
    />
    <circle cx="6.401" cy="13.165" r="2.191" fill="#A7A7A7" />
    <circle cx="8.879" cy="13.165" fill="#A7A7A7" r="2.191" />
  </svg>
</template>
