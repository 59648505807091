<template>
  <div
    class="app-container"
    :class="{
      'sticky-product-summary-is-visible': isShowStickyProductSummary && !productGalleryOpen,
      'sticky-apple-pay-is-visible': isShowStickyApplePaySummary,
      'gallery--open': productGalleryOpen,
      'pdp-view': isPDPView,
    }"
  >
    <!-- The initial spinner when the app is just starting up -->
    <div v-if="loading.app" aria-label="Loading" class="loader-container">
      <transition name="fade" mode="out-in">
        <lottie-animation :animationData="loaderAnimation" :loop="true" />
      </transition>
    </div>

    <!-- Splash Screen after app has loaded -->
    <template v-if="loading.logo">
      <transition name="fade" mode="out-in">
        <PaymentPopupSplashScreen v-if="isPaymentPopup" />
        <SplashScreen v-else />
      </transition>
    </template>

    <!-- The key somponents have loaded, show the checkout view -->
    <template v-if="!loading.app">
      <div class="main-container" ref="mainCont" v-show="!loading.logo">
        <!-- PDP view header-->
        <HeaderComponent v-if="isPDPView" />

        <!-- Default header-->
        <header v-else class="header-container" :style="`background: ${primaryColor}`">
          <header v-if="logo" :aria-label="companyName">
            <Logo class="logo-wrap" />
          </header>

          <button
            v-if="isShowHeaderCloseButton"
            type="button"
            class="button button--close"
            data-test="header-close-button"
            @click="closeCheckout()"
          >
            <IconClose />
            Close
          </button>

          <button
            v-if="isShowHeaderBackButton"
            type="button"
            class="button button--back colour-white"
            @click.prevent="doPreviousStepInCheckout()"
          >
            <IconBack />
            Back
          </button>
        </header>

        <main style="background: white">
          <div v-if="isShowHeaderBackButton && isPDPView" class="back-button-container">
            <button
              type="button"
              class="button button--back colour-grey"
              @click.prevent="doPreviousStepInCheckout()"
            >
              <IconBack />
              Back
            </button>
          </div>
          <router-view v-slot="{ Component, route }">
            <transition name="fade" mode="out-in">
              <div :key="route.name">
                <component :is="Component" />
              </div>
            </transition>
          </router-view>
        </main>

        <Footer v-if="isShowFooter" />

        <LaunchPaymentPopup
          v-if="
            !isPaymentPopup &&
            getIsShopFrontInitialised &&
            paymentPopupState &&
            paymentPopupState !== 'IS_CLOSED'
          "
          data-test="launch-payment-poupup"
          :paymentPopupState="paymentPopupState"
          :paymentProviderToken="paymentConfig?.paymentProviderToken"
          :cartJwt="cartJwt"
          :paymentProvider="paymentProvider"
        />
      </div>
    </template>
  </div>
</template>

<script>
import { mapState, mapActions } from 'pinia';
import { useMainStore } from '@/stores/MainStore.ts';
import { useProductStore } from '@/stores/ProductStore.ts';
import { useCartStore } from '@/stores/CartStore.ts';
import { usePaymentStore } from '@/stores/PaymentStore.ts';

import SplashScreen from '@/components/SplashScreen/SplashScreen.vue';
import Logo from '@/components/Logo/index.vue';
import IconClose from '@/components/icons/IconClose.vue';
import IconBack from '@/components/icons/IconBack.vue';
import IconLogo from '@/components/icons/IconLogo.vue';
import PaymentPopupSplashScreen from './components/SplashScreen/PaymentPopupSplashScreen.vue';
import LaunchPaymentPopup from './components/Payment/LaunchPaymentPopup.vue';
import Footer from '@/components/Footer/index.vue';
import HeaderComponent from '@/components/HeaderComponent/index.vue';

import constants from '@/constants';
import loader from './assets/loader.json';
import { useAdvertiserStore } from '@/stores/AdvertiserStore';
import { getRouter } from '@/router';
import gtmTracker from '@/helpers/googleTagManager';
import { ShopthruError } from '@/types/errors.types';
import { LocalStorageHandler } from '@/helpers/localStorage/index';

export default {
  components: {
    Logo,
    IconLogo,
    IconClose,
    IconBack,
    SplashScreen,
    PaymentPopupSplashScreen,
    LaunchPaymentPopup,
    Footer,
    HeaderComponent,
  },
  computed: {
    ...mapState(useMainStore, [
      'loading',
      'isPreviewMode',
      'isPDPView',
      'isPaymentPopup',
      'paymentPopupState',
      'getIsShopFrontInitialised',
    ]),
    ...mapState(usePaymentStore, ['paymentConfig']),
    ...mapState(useAdvertiserStore, ['primaryColor', 'paymentProvider', 'logo', 'companyName']),
    ...mapState(useProductStore, [
      'isShowStickyProductSummary',
      'isShowStickyApplePaySummary',
      'productGalleryOpen',
    ]),
    ...mapState(useCartStore, ['cartJwt']),

    loaderAnimation() {
      return loader;
    },

    isShowHeaderCloseButton() {
      return (
        [
          'Product',
          'Thankyou',
          'Error',
          'ProductOutOfStock',
          'ProductNotFound',
          'ProductOutOfStockPDP',
          'ProductNotFoundPDP',
        ].includes(this.$route.name) &&
        !this.isPreviewMode &&
        !this.isPaymentPopup
      );
    },

    isShowHeaderBackButton() {
      return (
        ![
          'Product',
          'Thankyou',
          'Error',
          'ProductOutOfStock',
          'ProductNotFound',
          'ShopProduct',
          'ProductOutOfStockPDP',
          'ProductNotFoundPDP',
        ].includes(this.$route.name) && !this.isPaymentPopup
      );
    },

    isShowFooter() {
      // TODO add Payment Popup when merged
      return ['Product'].includes(this.$route.name);
    },
  },
  setup() {
    const mainStore = useMainStore();
    const checkoutLocation = LocalStorageHandler.get('checkoutLocation');
    const checkoutLocationSearchParams = new URLSearchParams(checkoutLocation.search);
    const paymentPopupLocation = window.location;
    const paymentPopupupLocationSearchParams = new URLSearchParams(paymentPopupLocation.search);
    let mainStoreData;

    const isPaymentPopup =
      paymentPopupupLocationSearchParams.get(
        constants.PAYMENT_POPUP_WINDOW.isPaymentPopupLaunchIdentifier,
      ) === 'true'; // i.e. is dedicated popup window for payment workarounds

    // Payment Popup flow
    if (isPaymentPopup) {
      mainStoreData = {
        isPaymentPopup: true,
        code: null,
        parentLocation: null,
        isPreviewMode: false,
        isPDPView: false,
        userSupportedApplePayVersion: null,
      };
    }

    // Default flow
    else {
      mainStoreData = {
        code: checkoutLocation.pathname.split('/').pop(), // Get short code from last path fragment,
        parentLocation: checkoutLocationSearchParams.get('parentlocation'),
        isPreviewMode: checkoutLocationSearchParams.get('isPreviewMode') === 'true',
        isPDPView: checkoutLocation.pathname.split('/').includes('shop'),
        userSupportedApplePayVersion: checkoutLocationSearchParams.get('applePayVersion'),
        isPaymentPopup: false,
      };
    }

    mainStore.loadJsAssets();
    mainStore.setData(mainStoreData);

    // Redirect to Payment Popup is specified
    if (isPaymentPopup) {
      getRouter().then((router) => {
        router.replace({ name: 'PaymentPopup' });
      });
    }
  },
  created() {
    const component = this;

    // Listen for unhandled promise rejections
    window.addEventListener('unhandledrejection', (e) => {
      const reasonObj = e.reason;

      if (import.meta.env.VITE_APP_ENV !== 'PROD') {
        console.info('Dev note - global unhandledrejection handler called', reasonObj);
      }

      gtmTracker.trackJourneyEvent({
        event: 'unhandledrejection_error_thrown',
      });

      let isGoToErrorPage = false;

      // Whitelist the error types so we don't go to error page on just any unhandled error event
      if (Object.getPrototypeOf(reasonObj) instanceof ShopthruError) {
        // Handle all appropriate payment errors by going to the error page, this will be more finely tuned moving forwards.
        isGoToErrorPage = true;

        // Ignore the error thrown when user closes the Google Pay dialogue
        if (reasonObj.sourceError?.statusCode === 'CANCELED') {
          isGoToErrorPage = false;
        }
      }

      if (isGoToErrorPage) {
        e.preventDefault();

        component.goToErrorPage({
          error: reasonObj,
        });
      }
    });
  },
  mounted() {
    // Make this Vue instance accessible from the payment popup
    window.ParentVueInstance = this;

    gtmTracker.trackJourneyEvent({
      event: 'app_loaded',
    });
  },
  methods: {
    ...mapActions(useMainStore, [
      'closeCheckout',
      'previousStepInCheckout',
      'goToErrorPage',
      'onLaunchPaymentPopup',
    ]),

    async doPreviousStepInCheckout() {
      const router = await getRouter();
      this.previousStepInCheckout(router);
    },
  },
};
</script>

<style lang="scss">
@import './styles/_app.scss';
</style>
