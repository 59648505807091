<template>
  <div v-if="product.images?.length">
    <Carousel :images="product.images" />

    <div class="overlay" @click="toggleGalleryOverlay" />

    <div class="gallery-overlay-container">
      <button type="button" class="button button--close" @click="toggleGalleryOverlay">
        <IconCloseDark />
        Close
      </button>
      <section>
        <Carousel :images="product.images" />
        <div class="trailing-content">
          <!-- Buy now button -->
          <InPageBuyNowBtn class="pay-now-btn" />
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'pinia';
import { useProductStore } from '@/stores/ProductStore.ts';

import IconCloseDark from '@/components/icons/IconCloseDark.vue';
import Carousel from '@/components/Carousel/index.vue';
import InPageBuyNowBtn from '@/components/Product/InPageBuyNowBtn.vue';

export default {
  name: 'ProductGallery',
  components: {
    IconCloseDark,
    Carousel,
    InPageBuyNowBtn,
  },
  computed: {
    ...mapState(useProductStore, ['product']),
  },
  methods: {
    ...mapActions(useProductStore, ['toggleGalleryOverlay']),
  },
};
</script>

<style lang="scss" scoped>
@import './styles.scss';
</style>
