<template>
  <table
    class="shopthru-table border-colour-lighter-grey table--border-collapse font-size-xs"
  >
    <tr v-for="(header, index) in cells.headers">
      <th
        class="bg-colour-light-blue ph-10 pv-5 border-colour-lighter-grey text-align-left font-weight-normal border-1 vertical-align-top"
      >
        {{ header }}
      </th>
      <td class="pl-10 pv-5 pr-5 border-1 font-weight-light border-colour-lighter-grey vertical-align-top">{{ cells.content[index] }}</td>
    </tr>
  </table>
</template>

<script>
export default {
  name: 'HorizontalTable',
  props: {
    cells: {
      headers: [String],
      content: [[String]],
    },
  },
  computed: {},
  methods: {},
};
</script>

<style lang="scss" scoped>
@import './styles.scss';
</style>
