<template>
  <div class="form-container">
    <Totals />

    <BraintreeHostedFields />
  </div>
</template>

<script>
import { mapState, mapActions } from 'pinia';
import { useShippingStore } from '@/stores/ShippingStore';
import { useMainStore } from '@/stores/MainStore';
import { useCartStore } from '@/stores/CartStore';

import Totals from '@/components/Totals/index.vue';
import BraintreeHostedFields from '@/components/Payment/Braintree/BraintreeHostedFields/index.vue';
import { useAdvertiserStore } from '@/stores/AdvertiserStore';

import { getRouter } from '@/router';

export default {
  name: 'BraintreeCheckoutView',
  components: {
    Totals,
    BraintreeHostedFields,
  },
  async mounted() {
    if (
      !this.validateAddress('shipping', this.address.shipping) ||
      !this.cart.shipping?.selectedShippingMethod
    ) {
      const router = await getRouter();

      this.previousStepInCheckout(router);
    }
  },
  computed: {
    ...mapState(useShippingStore, ['address']),
    ...mapState(useAdvertiserStore, ['paymentProvider']),
    ...mapState(useCartStore, ['cart']),
  },
  methods: {
    ...mapActions(useShippingStore, ['validateAddress']),
    ...mapActions(useMainStore, ['previousStepInCheckout']),
  },
};
</script>
