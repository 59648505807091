<template>
  <svg
    class="icon-tick"
    width="20"
    height="16"
    viewBox="0 0 20 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M6.36364 12.8371L1.59091 8.13561L0 9.70278L6.36364 15.9714L20 2.5386L18.4091 0.971436L6.36364 12.8371Z"
      fill="#00B3A6"
    />
  </svg>
</template>
