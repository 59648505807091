import mitt from 'mitt';

const emitter = mitt();

export default {
  install: (app: ToDo) => {
    // Send a message to the parent window
    app.config.globalProperties.$eventEmitter = emitter;
  },
};
