import api from '../Api';

import type { JwtPayload } from 'jwt-decode';
import type { Cart } from '@/types/cart.types';
import type { ShippingMethod } from '@/stores/ShippingStore.ts';

export const getInitialisedCart = async (code: string, queryData?: Record<string, string>) => {
  const queryString: string = queryData ? `?${new URLSearchParams(queryData).toString()}` : '';

  return api.get(`checkout/cart/init/${code}${queryString}`);
};

export const setCartItems = ({
  cartId,
  payload,
  cartJwt,
}: {
  cartId: string;
  payload: Cart;
  cartJwt: JwtPayload;
}) => {
  return api.post(`checkout/cart/${cartId}/items`, payload!, {
    headers: {
      'Cart-Jwt': cartJwt,
    },
  });
};

export const setCartDiscounted = ({ cartId, cartJwt }: { cartId: string; cartJwt: JwtPayload }) => {
  return api.post(
    `checkout/cart/${cartId}/discount`,
    {},
    {
      headers: {
        'Cart-Jwt': cartJwt,
      },
    },
  );
};

export const getCartShippingMethods = ({
  cartId,
  payload,
  cartJwt,
}: {
  cartId: string;
  payload: Cart;
  cartJwt: JwtPayload;
}): Promise<{ data: { shipping_methods: ShippingMethod[] } }> =>
  api.post(`checkout/cart/${cartId}/shipping-methods`, payload!, {
    headers: {
      'Cart-Jwt': cartJwt,
    },
  });

export const setCartShipping = ({
  cartId,
  payload,
  cartJwt,
}: {
  cartId: string;
  payload: Cart;
  cartJwt: JwtPayload;
}) =>
  api.post(`checkout/cart/${cartId}/shipping`, payload!, {
    headers: {
      'Cart-Jwt': cartJwt,
    },
  });

export const setCartOrdered = ({
  cartId,
  payload,
  cartJwt,
}: {
  cartId: string;
  payload: Cart;
  cartJwt: JwtPayload;
}) =>
  api.post(`checkout/cart/${cartId}/order`, payload!, {
    headers: {
      'Cart-Jwt': cartJwt,
    },
  });

export const setCartOrderedPpcpPayPal = ({
  cartId,
  payload,
  cartJwt,
}: {
  cartId: string;
  payload: Cart;
  cartJwt: JwtPayload;
}) =>
  api.post(`checkout/cart/${cartId}/ppcp-paypal/order`, payload!, {
    headers: {
      'Cart-Jwt': cartJwt,
    },
  });

export const setCartOrderedTEMPAdyenExperiment = ({
  cartId,
  payload,
  cartJwt,
}: {
  cartId: string;
  payload: Cart;
  cartJwt: JwtPayload;
}) => {
  const mockServerUrl = `https://${import.meta.env.VITE_LOCAL_NODE_SERVER_URL}`;

  return api.post(
    'place-adyen-order',
    payload!,
    {
      headers: {
        'Cart-Jwt': cartJwt,
      },
    },
    mockServerUrl,
  );
};
