<template>
  <div
    v-if="product"
    class="product-view tw-grid tw-grid-cols-12 tw-gap-3"
    :class="{
      'md:tw-gap-7': !this.templateType,
    }"
    data-test="product-view-wrapper"
  >
    <!-- Main hero image and gallery -->
    <div class="product-imagery-container tw-row-span-3 tw-col-span-5">
      <div class="product-imagery-container__product-gallery">
        <ProductGallery />
      </div>
    </div>

    <!-- Product headline and pricing info -->
    <div
      class="product-headline-and-pricing-container md:tw-col-start-6 md:tw-col-span-12 tw-col-span-7"
    >
      <div class="product-info">
        <Product ref="productComponent" />
      </div>
    </div>

    <!-- Discount button, payment buttons and product details -->
    <div id="product-buttons-and-detail-container" class="tw-col-span-12 md:tw-col-start-6">
      <div
        v-if="
          getIsShopFrontInitialised &&
          cart?.pricing &&
          (!isProductHasVariants ||
            (getIsProductOptionsSelectionComplete && getIsProductOptionsSelectionValid))
        "
      >
        <div v-if="isShowDiscountUiDevice" id="product-promocode-container">
          <Promocode
            :key="promocodeComponentKey"
            data-test="promocode-device"
            :doReloadComponent="resetDiscountDevice"
          />
        </div>

        <div class="payment-buttons-wrapper">
          <div ref="paymentButtonsComponentMarker" />

          <!-- Show a static image of the payment buttons when in Preview Mode -->
          <img v-if="isPreviewMode" :src="previewPaymentButtonsImgSrc" class="pt-20" />

          <Payment v-else :loadStripe="() => {}" />
        </div>
      </div>

      <div v-else class="main-content-loader skeleton-loader tw-col-span-12" />

      <div v-if="product.shortDescription?.length" class="mt-20">
        {{ product.shortDescription }}
      </div>

      <ProductDetails />
      <ProductFeatures />
      <ProductDelivery />
      <InformationTab />
    </div>
  </div>
</template>

<script>
import { mapState, mapWritableState, mapActions } from 'pinia';
import { useMainStore } from '@/stores/MainStore';
import { useCartStore } from '@/stores/CartStore';
import { useAdvertiserStore } from '@/stores/AdvertiserStore';
import { useProductStore } from '@/stores/ProductStore.ts';
import { usePublisherStore } from '@/stores/PublisherStore.js';
import gtmTracker from '@/helpers/googleTagManager.ts';
import Product from '@/components/Product/index.vue';
import ProductGallery from '@/components/ProductGallery/index.vue';
import ProductFeatures from '@/components/ProductFeatures/index.vue';
import ProductDetails from '@/components/ProductDetails/index.vue';
import ProductDelivery from '@/components/ProductDelivery/index.vue';
import Payment from '@/components/Payment/index.vue';
import Promocode from '@/components/Promocode/index.vue';
import InformationTab from '@/components/InformationTab/index.vue';
import TermsTab from '@/components/Terms/index.vue';
import previewPaymentButtonsImg from '@/assets/static-preview-payment-buttons.jpg';

export default {
  components: {
    InformationTab,
    TermsTab,
    Product,
    ProductGallery,
    ProductFeatures,
    ProductDetails,
    ProductDelivery,
    Payment,
    Promocode,
  },
  data() {
    return {
      promocodeComponentKey: 0,
      intersectionObserverStickyHeader: null,
      intersectionObserverStickyHeaderExtended: null,
    };
  },
  computed: {
    ...mapState(useMainStore, [
      'getIsShopFrontInitialised',
      'getIsShowPricingElements',
      'isPreviewMode',
      'templateType',
    ]),
    ...mapState(useCartStore, [
      'cart',
      'getIsProductOptionsSelectionComplete',
      'getIsProductOptionsSelectionValid',
      'isDiscountRevoked',
    ]),
    ...mapState(useAdvertiserStore, ['advertiserName', 'companyName']),
    ...mapState(useProductStore, ['product', 'isProductHasVariants', 'discountLookupState']),
    ...mapState(usePublisherStore, ['publisherUrl']),

    ...mapWritableState(useCartStore, ['isDiscountRevoked']),

    ...mapWritableState(useProductStore, [
      'isShowStickyProductSummary',
      'isShowStickyProductSummaryExtended',
    ]),
    isShowDiscountUiDevice() {
      if (this.templateType === 'PILOT_BLACK_FRIDAY_NOV_24') {
        return false;
      }

      if (this.isProductHasVariants) {
        return this.getIsProductOptionsSelectionComplete;
      } else {
        return true;
      }
    },
    previewPaymentButtonsImgSrc() {
      return previewPaymentButtonsImg;
    },
  },
  methods: {
    ...mapActions(useCartStore, ['getSelectedProductRegularPrice']),
    ...mapActions(useProductStore, ['setDiscountLookupState']),

    resetDiscountDevice() {
      // We are using the 'Key-Changing Technique' to simply a ref-render the Promocode component afresh.
      this.setDiscountLookupState(null);
      this.promocodeComponentKey += 1;
    },

    initIntersectionObservers() {
      const pricingRef = this.$refs.productComponent.$refs.pricingRef;
      const paymentButtonsRef = this.$refs.paymentButtonsComponentMarker;

      this.disconnectIntersectionObservers();

      // Sticky Header
      if (pricingRef) {
        this.intersectionObserverStickyHeader = new IntersectionObserver(
          (entries) => {
            this.isShowStickyProductSummary = !entries[0].isIntersecting;
          },
          { root: document, rootMargin: '-100px' },
        );
        this.intersectionObserverStickyHeader.observe(pricingRef);
      }

      // Sticky Header Extended (i.e. include Buy Now button in sticky header)
      if (paymentButtonsRef) {
        this.intersectionObserverStickyHeaderExtended = new IntersectionObserver(
          (entries) => {
            this.isShowStickyProductSummaryExtended = !entries[0].isIntersecting;
          },
          { root: document },
        );
        this.intersectionObserverStickyHeaderExtended.observe(paymentButtonsRef);
      }
    },

    disconnectIntersectionObservers() {
      this.intersectionObserverStickyHeader?.disconnect();
      this.intersectionObserverStickyHeaderExtended?.disconnect();
    },
  },
  mounted() {
    gtmTracker.trackJourneyEvent({
      event: 'view_product',
      label: 'Product has been loaded',
      product_id: this.product?.id,
      product_name: this.product?.name,
      price: this.getSelectedProductRegularPrice,
      advertiser_name: this.advertiserName,
      company_name: this.companyName,
      noninteraction: false,
      publisherUrl: this.publisherUrl,
    });
  },
  beforeUnmount() {
    this.disconnectIntersectionObservers();
  },
  watch: {
    cart(newVal, oldVal) {
      /**
       * Discount revoked is inferred by sensing when a discount has been applied but then removed in a subsequent cart change
       * (e.g. the original discount is no longer applicable based on the new cart contents).
       */
      const discountStatePreviouslySet = !!oldVal && !!newVal && oldVal.isDiscountApplied !== null;
      const isDiscountRevoked =
        discountStatePreviouslySet &&
        newVal.isDiscountApplied === false &&
        oldVal.isDiscountApplied === true;

      if (isDiscountRevoked) {
        this.setDiscountLookupState('DISCOUNT_REVOKED');
      }

      this.isDiscountRevoked = isDiscountRevoked;
    },
    discountLookupState(newVal) {
      if (newVal === 'LOOKUP_ERRED') {
        // this.resetDiscountDevice();
      }
    },
    getIsShowPricingElements(newVal, oldVal) {
      if (!oldVal && newVal) {
        setTimeout(() => {
          this.initIntersectionObservers();
        }, 0);
      } else if (!newVal) {
        this.disconnectIntersectionObservers();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import './Product.scss';
</style>
