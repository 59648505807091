const debounce = (fn: () => any, timeout: number) => {
  let timer = -1;

  clearTimeout(timer);

  timer = setTimeout(() => {
    fn();
  }, timeout);
};

/**
 * Create an interval that periodically checks for the existance of the property specified on the supplied object.
 * For example, wait for window.google to have been loaded after a script has been downloaded.
 *
 * @param object Object The object whose property to wait on
 * @param property String The property to wait on
 * @param timeout Number The maximum wait time in milliseconds
 * @returns Promise<boolean> Will return true if property is found, false if not after the maximum timeout
 */
const awaitInstanceInitialised = ({
  object,
  property,
  timeout = 5000,
}: {
  object: any;
  property: string;
  timeout: number;
}) => {
  const startTime = Date.now();
  let checkInterval: number;

  return new Promise((res) => {
    const check = () => {
      if (object[property]) {
        clearInterval(checkInterval);
        return true;
      } else {
        const newTime = Date.now();

        // Stop interval after a period of time
        if (newTime - startTime >= timeout) {
          clearInterval(checkInterval);
          console.error(`Timed out waiting for initialisation of target property ${property}`);
        }

        return false;
      }
    };

    checkInterval = window.setInterval(() => {
      if (check()) {
        res(true);
      }
    }, 200);
  });
};

export const Utils = {
  debounce,
  awaitInstanceInitialised,
};
