type ShopThruPostMessageProps = {
  type: string;
  key: string;
  message?: string;
};

class ShopThruPostMessage {
  private type: string;
  private key: string;
  private message?: string;

  constructor(msg: ShopThruPostMessageProps) {
    this.type = msg.type;
    this.key = msg.key;
    this.message = msg.message;
  }

  getMessage(): ShopThruPostMessageProps {
    return {
      type: this.type,
      key: this.key,
      message: this.message,
    };
  }
}

type DictionaryMessage = {
  key: string;
  description?: string;
};

export const messageDictionary: Record<string, Record<string, DictionaryMessage>> = {
  CHECKOUT_PAYMENT_POPUP: {
    PAYMENT_COMPLETE_RESPONSE: {
      key: 'PAYMENT_COMPLETE_RESPONSE',
      description: 'From popup window when payment is complete.',
    },
  },
};

const expectedOrigin = ''; // Get from Parent domain state prop;

export const handleInboundPostMessage = (e) => {
  if (typeof e.data !== 'object') {
    return;
  }

  const { data } = e;
  const { key, message, type } = data;
  let matchedMessage: DictionaryMessage;

  if (false) {
    // TODO expectedOrigin !== e.origin) {
    return;
  }

  try {
    matchedMessage = messageDictionary[type][key];

    if (matchedMessage) {
      switch (type) {
        case 'CHECKOUT_PAYMENT_POPUP':
          window.dispatchEvent(
            new CustomEvent('paymentPopupPostMessageEvent', { detail: { key, message } }),
          );
          break;

        default:
          break;
      }
    }
  } catch {
    // No need for noisy logs
  }
};

// Listen for inbound postMessage events coming from the Embed script in the parent window
export const initInboundPostMessaging = () => {
  window.addEventListener('message', handleInboundPostMessage);
};

export default {
  // Inject an outbound postMessage helper to Vue
  install: (app: ToDo) => {
    // Send a message to the parent window
    app.config.globalProperties.$postMessenger = (msg: ShopThruPostMessageProps) => {
      try {
        parent.window.postMessage(new ShopThruPostMessage(msg).getMessage(), '*');
      } catch (err) {
        console.error('Failed to send post message to parent window!', err);
      }
    };

    // Send a message to the opener window
    app.config.globalProperties.$postMessengerOpener = (msg: ShopThruPostMessageProps) => {
      try {
        window.opener.postMessage(new ShopThruPostMessage(msg).getMessage(), '*');
      } catch (err) {
        console.error('Failed to send post message to opener window!', err);
      }
    };
  },
};
