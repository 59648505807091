<template>
  <ProductIssue :outboundLinkHref="getFallbackUrl">
    <div class="product-out-of-stock-wrapper">
      <div class="product-image">
        <img
          :src="product?.images[0]?.src"
          :onerror="`this.onerror=null; this.src='${placeholder()}';`"
        />
      </div>

      <div class="issue-info">
        <div>
          {{ product?.name }}
        </div>
        <div class="out-of-stock-message">
          <lottie-animation :animationData="errorAnimation" :auto-play="true" class="icon" />
          <div>Out of stock</div>
        </div>
      </div>
    </div>
  </ProductIssue>
</template>

<script>
import ProductIssue from '@/components/ProductIssue/ProductIssue.vue';
import { mapState } from 'pinia';
import { useMainStore } from '@/stores/MainStore.ts';
import { useProductStore } from '@/stores/ProductStore.ts';
import errorAnimation from '@/assets/lottie/error.json';
import placeholder from '@/assets/placeholder.svg';

export default {
  name: 'ProductOutOfStock',
  components: {
    ProductIssue,
  },
  computed: {
    ...mapState(useMainStore, ['getFallbackUrl']),
    ...mapState(useProductStore, ['product']),

    errorAnimation() {
      return errorAnimation;
    },
  },
  methods: {
    placeholder() {
      return placeholder;
    },
  },
};
</script>

<style lang="scss" scoped>
@import './ProductOutOfStock.scss';
</style>
