<template>
  <svg
    width="9px"
    height="9px"
    viewBox="0 0 9 9"
    id="svg8"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:cc="http://creativecommons.org/ns#"
    xmlns:dc="http://purl.org/dc/elements/1.1/"
    xmlns:inkscape="http://www.inkscape.org/namespaces/inkscape"
    xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#"
    xmlns:sodipodi="http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd"
    xmlns:svg="http://www.w3.org/2000/svg"
  >
    <defs id="defs2" />

    <g id="layer1" transform="translate(0,-288.53332)">
      <path
        d="M 16,1 C 12.139297,1 9,4.1392882 9,8 v 5 H 7 c -0.5522619,5.5e-5 -0.9999448,0.447738 -1,1 v 16 c 5.52e-5,0.552262 0.4477381,0.999945 1,1 h 18 c 0.552262,-5.5e-5 0.999945,-0.447738 1,-1 V 14 c -5.5e-5,-0.552262 -0.447738,-0.999945 -1,-1 H 23 V 8 C 23,4.1392882 19.860703,1 16,1 Z m 0,2 c 2.787297,0 5,2.212674 5,5 v 5 H 11 V 8 C 11,5.212674 13.212703,3 16,3 Z M 8,15 H 24 V 29 H 8 Z"
        id="rect864"
        style="
          color: #000000;
          font-style: normal;
          font-variant: normal;
          font-weight: normal;
          font-stretch: normal;
          font-size: medium;
          line-height: normal;
          font-family: sans-serif;
          font-variant-ligatures: normal;
          font-variant-position: normal;
          font-variant-caps: normal;
          font-variant-numeric: normal;
          font-variant-alternates: normal;
          font-feature-settings: normal;
          text-indent: 0;
          text-align: start;
          text-decoration: none;
          text-decoration-line: none;
          text-decoration-style: solid;
          text-decoration-color: #000000;
          letter-spacing: normal;
          word-spacing: normal;
          text-transform: none;
          writing-mode: lr-tb;
          direction: ltr;
          text-orientation: mixed;
          dominant-baseline: auto;
          baseline-shift: baseline;
          text-anchor: start;
          white-space: normal;
          shape-padding: 0;
          clip-rule: nonzero;
          display: inline;
          overflow: visible;
          visibility: visible;
          opacity: 1;
          isolation: auto;
          mix-blend-mode: normal;
          color-interpolation: sRGB;
          color-interpolation-filters: linearRGB;
          solid-color: #000000;
          solid-opacity: 1;
          vector-effect: none;
          fill: #000000;
          fill-opacity: 1;
          fill-rule: nonzero;
          stroke: none;
          stroke-width: 1.99999988;
          stroke-linecap: round;
          stroke-linejoin: round;
          stroke-miterlimit: 4;
          stroke-dasharray: none;
          stroke-dashoffset: 0;
          stroke-opacity: 1;
          paint-order: stroke fill markers;
          color-rendering: auto;
          image-rendering: auto;
          shape-rendering: auto;
          text-rendering: auto;
          enable-background: accumulate;
        "
        transform="matrix(0.26458333,0,0,0.26458333,0,288.53332)"
      />

      <path
        d="m 4.4979169,294.36786 a 0.26458332,0.26458332 0 0 1 -0.2645833,0.26458 0.26458332,0.26458332 0 0 1 -0.2645833,-0.26458 0.26458332,0.26458332 0 0 1 0.2645833,-0.26458 0.26458332,0.26458332 0 0 1 0.2645833,0.26458 z"
        id="path877"
        style="
          opacity: 1;
          fill: #000000;
          fill-opacity: 1;
          stroke: none;
          stroke-width: 0.52916664;
          stroke-linecap: round;
          stroke-linejoin: round;
          stroke-miterlimit: 4;
          stroke-dasharray: none;
          stroke-dashoffset: 0;
          stroke-opacity: 1;
          paint-order: stroke fill markers;
        "
      />
    </g>
  </svg>
</template>
