<template>
  <section class="order-total-section">
    <span>Order Total</span>
    <strong v-text="getFormattedPrice(getTotalPaymentPrice)"></strong>
  </section>
</template>

<script>
import { mapActions, mapState } from 'pinia';
import { useCartStore } from '@/stores/CartStore';

export default {
  name: 'TotalsComponent',
  computed: {
    ...mapState(useCartStore, ['getTotalPaymentPrice']),
  },
  methods: {
    ...mapActions(useCartStore, ['getFormattedPrice']),
  },
};
</script>

<style lang="scss" scoped>
@import './styles.scss';
</style>
