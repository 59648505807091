<template>
  <div>
    <h4>Your card details</h4>

    <div>
      <!-- Cardholder name -->
      <div id="card-name" class="bthf-field ___TODO"></div>

      <!-- Card number -->
      <div id="card-number" class="bthf-field ___TODO"></div>

      <!-- Expiration Date -->
      <div id="card-expiration-date" class="bthf-field ___TODO"></div>

      <!-- CVV -->
      <div id="card-cvv" class="bthf-field ___TODO"></div>

      <div v-if="!isFormValid" class="colour-danger pb-10">
        Some fields are incorrect, please check and try again.
      </div>

      <button class="button button--primary button--full-width" @click="doSubmit()">
        <span>Buy Now</span>
      </button>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'pinia';
import { useMainStore } from '@/stores/MainStore';
import { usePaymentStore } from '@/stores/PaymentStore';
import { useShippingStore } from '@/stores/ShippingStore.ts';
import { useCartStore } from '@/stores/CartStore.ts';
import { useAdvertiserStore } from '@/stores/AdvertiserStore';
import gtmTracker from '@/helpers/googleTagManager.ts';
import { HostedFieldsPaymentError } from '@/types/errors.types';

export default {
  name: 'PPCPCard',
  components: {},
  props: {
    token: String,
    paypalApi: { type: Object, required: false }, // Can be overriden in tests
  },
  data() {
    return {
      isRenderComponent: true,
      cardFields: null,
      isFormValid: true,
    };
  },
  computed: {
    ...mapState(useShippingStore, ['address']),
    ...mapState(useCartStore, ['cart']),
    ...mapState(useAdvertiserStore, ['paymentProvider']),
  },
  async mounted() {
    await this.init();
  },
  beforeUnmount() {
    if (this.cardFields?.close) {
      this.cardFields.close();
    }
  },
  methods: {
    ...mapActions(useMainStore, ['goToErrorPage', 'setPaymentButtonInitialised']),
    ...mapActions(usePaymentStore, ['placePPCPOrder']),

    onPaymentError(error) {
      if (this.cardFields?.close) {
        this.cardFields.close();
      }

      this.goToErrorPage({
        error,
      });
    },

    async init() {
      const paypalApi = this.paypalApi || window.paypal;

      try {
        const cardFields = paypalApi.CardFields({
          createOrder: () => {
            return Promise.resolve(this.cart.pspOrderId);
          },
          onApprove: this.placeOrder,
          onError: (err) => {
            this.onPaymentError(
              new HostedFieldsPaymentError('Error in card payment!', err, this.paymentProvider),
            );
          },
          style: {
            input: {
              // 'font-size': '16px',
              // 'font-family': 'courier, monospace',
              'font-weight': 'bold',
              // border: 'none',
              color: 'black',
              // 'border-color': 'red',
              height: '1px',
            },
            '.invalid': {
              color: 'red',
            },
          },
        });

        const nameField = cardFields.NameField({
          /*
          style: {
            input: {
              color: 'blue',
            },
            '.invalid': {
              color: 'purple',
            },
          },
          */
        });
        nameField.render('#card-name');

        const numberField = cardFields.NumberField();
        numberField.render('#card-number');

        const expiryField = cardFields.ExpiryField();
        expiryField.render('#card-expiration-date');

        const cvvField = cardFields.CVVField();
        cvvField.render('#card-cvv');

        this.cardFields = cardFields;
      } catch (err) {
        throw new HostedFieldsPaymentError('Error in init()!', err, this.paymentProvider);
      }
    },

    async doSubmit() {
      try {
        const cardState = await this.cardFields.getState();

        // Submit only if the form is valid
        if (cardState.isFormValid) {
          result = await this.cardFields.submit({
            contingencies: ['SCA_ALWAYS'],
            billingAddress: {
              addressLine1: this.address.billing.streetAddress[0],
              addressLine2: this.address.billing.streetAddress[1],
              adminArea1: this.address.billing.city,
              adminArea2: this.address.billing.region,
              countryCode: this.address.billing.country,
              postalCode: this.address.billing.postalCode,
            },
          });
        } else {
          this.isFormValid = false;
        }
      } catch (err) {
        throw new HostedFieldsPaymentError('Error in onSubmit()!', err, this.paymentProvider);
      }

      return Promise.resolve(true);
    },

    async placeOrder() {
      try {
        await this.placePPCPOrder('card');

        gtmTracker.doPlaceOrderGtmEvent({
          paymentType: 'Card Payment',
        });
      } catch (err) {
        throw new HostedFieldsPaymentError('Error in placeOrder()!', err, this.paymentProvider);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import './styles.scss';
</style>
