import { useMediaQuery } from '@vueuse/core';
import sassVariables from '@/styles/global/_jsVariables.module.scss';

enum ScreenSizeEnum {
  'xxs',
  'xs',
  's',
  'm',
  'l',
}

type ScreenSize = keyof typeof ScreenSizeEnum;

// As per the variables defined in styles/utility/_variables.scss
const atLeastSizeQueries: Record<ScreenSize, string> = {
  xxs: `(min-width: ${sassVariables.screenWidth_xxs})`,
  xs: `(min-width: ${sassVariables.screenWidth_xs})`,
  s: `(min-width: ${sassVariables.screenWidth_s})`,
  m: `(min-width: ${sassVariables.screenWidth_m})`,
  l: `(min-width: ${sassVariables.screenWidth_l})`,
};

// As per the variables defined in styles/utility/_variables.scss
const lessThanSizeQueries: Record<ScreenSize, string> = {
  xxs: `(max-width: ${sassVariables.screenWidth_xxs})`,
  xs: `(max-width: ${sassVariables.screenWidth_xs})`,
  s: `(max-width: ${sassVariables.screenWidth_s})`,
  m: `(max-width: ${sassVariables.screenWidth_m})`,
  l: `(max-width: ${sassVariables.screenWidth_l})`,
};

export default (app: ToDo) => {
  // Keeping as a hint in case ever required one day
  // app.provide('isScreenSizeAtLeast', () => ...);

  // E.g. this.$isScreenSizeAtLeast('xs')
  app.config.globalProperties.$isScreenSizeAtLeast = (size: ScreenSize) => {
    if (!(size in ScreenSizeEnum)) {
      throw new Error(`Invalid size ${size} specified in call to isScreenSizeAtLeast()!`);
    }

    return useMediaQuery(atLeastSizeQueries[size]).value;
  };

  // E.g. this.$isScreenSizeLessThan('xs')
  app.config.globalProperties.$isScreenSizeLessThan = (size: ScreenSize) => {
    if (!(size in ScreenSizeEnum)) {
      throw new Error(`Invalid size ${size} specified in call to isScreenSizeAtLeast()!`);
    }

    return useMediaQuery(lessThanSizeQueries[size]).value;
  };
};
